.custom-header {
  display: flex;
  justify-content: space-between;
  padding: 18px 30px;
  border-bottom: 1px solid #e8e8e8;

  @media screen and (max-width: 1200px) and (min-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: 765px) {
    flex-direction: row; 
    justify-content: space-between;
    flex-wrap: nowrap; 
    gap: 8px; 
  }

  div {
    display: flex;
    align-items: center;

    @media screen and (max-width: 765px) {
      flex: 1 1 auto; 
      justify-content: center;
      gap: 4px; 
    }
  }
}
.header-mobile-settings {
  display: flex;
  align-items: center;
  padding-left: 50px;
  gap: 10px;
}

.custom-header > div > i {
  margin-right: 3px;
}
.dark-theme .custom-header {
  background-color: #434f5a;
  border-bottom: 1px solid #525e68;
}
.power-container {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  @media screen and (max-width: @screen-sm-max) {
    display: none !important;
  }
}

.power-mobile-indicator {
  @media screen and (min-width: @screen-sm-max) {
    display: none;
  }
}
.header-value-mobile {
  display: flex !important ;
  flex-direction: column;
}

.header-value-mobile > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-header-content {
  display: flex !important;
  gap: 15px;
  @media screen and (max-width: @screen-sm-max) {
    display: none !important;
  }
}
.custom-header-content-mobile {
  display: none !important;
  @media screen and (max-width: @screen-sm-max) {
    display: flex !important;
  }
}
.header-desktop-value {
  gap: 10px;
}
